import {
  BODY_COLLECTION,
  CAT_BODY_COLLECTION,
  IMAGE_FRAGMENT,
  HEADER_OVERLAY_BANNER,
  HEADER_OVERLAY_BUTTON,
  PAGE_ALERT_BANNER,
  HEADER_OVERLAY_TILES
} from 'graphql/fragments';
import { CONTENT_TYPES } from 'utils/constants';

export const categoryPageQuery = `
${IMAGE_FRAGMENT}
${HEADER_OVERLAY_BANNER}
${HEADER_OVERLAY_BUTTON}
${PAGE_ALERT_BANNER}
${CAT_BODY_COLLECTION}
${HEADER_OVERLAY_TILES}
query CategoryPageQuery($slug: [String], $preview: Boolean) {
categoryPageCollection(limit: 1, where: { slug_in: $slug }, preview: $preview) {
items {
...on ${CONTENT_TYPES.CATEGORY_PAGE} {
  title
  pageTitleMeta: pageTilteMeta
  pageDescriptionMeta
  slug
  headlineH1
  subhead
  heroImageMobile{
    ...Image
  }
  heroImage {
    ...Image
  }
  pageAlertBanner {
    ...PageAlertBannerFragment
  }
  headerOverlayContentCollection {
    items {
      ...on ${CONTENT_TYPES.HEADER_OVERLAY_BANNER} {
        ...HeaderOverlayBannerFragment
      } 
      ...on ${CONTENT_TYPES.HEADER_OVERLAY_BUTTON} {
        ...HeaderOverlayButtonFragment
      }
      ...on ${CONTENT_TYPES.HEADER_OVERLAY_TILES} {
        ...HeaderOverlayTilesFragment
      }
    }
  }
  bodyCollection(limit: 8) {
    ...categoryBodyCollectionFragment
  }
}
}
}
}
`;

export const pageQuery = `
${IMAGE_FRAGMENT}
${HEADER_OVERLAY_BANNER}
${HEADER_OVERLAY_BUTTON}
${PAGE_ALERT_BANNER}
${BODY_COLLECTION}
query PageQuery($slug: [String], $preview: Boolean) {
pageCollection(limit: 1, where: { slug_in: $slug }, preview: $preview) {
items {
...on ${CONTENT_TYPES.PAGE} {
  title
  pageTitleMeta: pageTilteMeta
  pageDescriptionMeta
  slug
  headlineH1
  subhead
  pageAlertBanner{
    ...PageAlertBannerFragment
  }
  headerOverlayContentCollection {
    items {
      ...on ${CONTENT_TYPES.HEADER_OVERLAY_BANNER} {
        ...HeaderOverlayBannerFragment
      } 
      ...on ${CONTENT_TYPES.HEADER_OVERLAY_BUTTON} {
        ...HeaderOverlayButtonFragment
      }
    }
  }
  bodyCollection(limit: 8) {
    ...bodyCollectionFragment
  }
}
}
}
}
`;
